import { businessFormMap } from './BusinessFormData';
const NOW = new Date().getTime();
const WEEK_SEC = 1000 * 60 * 60 * 24 * 7;
const initCache = () => {
  let data;
  const dataString = window.localStorage.getItem('hexin_business_form');
  if (dataString) {
    data = JSON.parse(dataString);
    const { storeTime } = data;
    const ifChange = JSON.stringify(data?.formMap) !== JSON.stringify(businessFormMap);
    if (storeTime - NOW > WEEK_SEC || ifChange) {
      data = {
        storeTime: NOW,
        formMap: businessFormMap,
      };
      const dataString = JSON.stringify(data);
      window.localStorage.setItem('hexin_business_form', dataString);
    }
  } else {
    const data = {
      storeTime: NOW,
      formMap: businessFormMap,
    };
    const dataString = JSON.stringify(data);
    window.localStorage.setItem('hexin_business_form', dataString);
  }
};
initCache();
