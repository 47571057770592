/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'excel': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M78.2 16.6V25H117v5.3H78.2V39H117v5.4H78.2V53H117v5.4H78.2v8.5H117v5.4H78.2V81H117v5.4H78.2v8.5H117v5.4H78.2v11.1H128V16.6H78.2zM0 114.4L72.1 128V0L0 13.6v100.8z"/><path pid="1" d="M28.7 82.6H17.5L32.3 59 18.2 36.5h11.5l8.2 15 8.4-15h11.2L43.4 58.7l15 23.9H46.7l-8.8-15.7z"/>'
  }
})
