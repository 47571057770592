import { GetterTree } from 'vuex';

const getters: GetterTree<any, any> = {
  user (state: any): any {
    return state.user;
  },
  routers (state: any): any {
    return state.routers;
  },
  uploading (state: any): any {
    return state.uploading;
  },
  eduInfo (state: any): any {
    return state.eduInfo;
  },
  order_id (state: any): any {
    return state.order_id;
  },
  customerAppKey (state: any): any {
    return state.customerAppKey;
  },
  formMap (state: any): any {
    return state.formMap;
  },
  organization (state: any): any {
    return state.organization;
  },
};

export default getters;
