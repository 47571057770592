import axios from 'axios';
// import router from '@/router';
// @ts-ignore
import isArray from 'lodash/isArray';
// @ts-ignore
import isPlainObject from 'lodash/isPlainObject';
import { Message } from 'element-ui';
import '@/styles/element-variables.scss';
axios.defaults.withCredentials = true;

const initParams = (params: any) => {
  const type = Object.prototype.toString.call(params);
  const needIterate = ['[object Object]', '[object Array]'];
  if (!needIterate.includes(type)) {
    return params;
  }
  if (type === '[object Object]') {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const val = params[key];
        const valType = Object.prototype.toString.call(val);
        if (key.startsWith('$$')) {
          delete params[key];
        } else {
          if (needIterate.includes(valType)) {
            initParams(val);
          }
        }
      }
    }
  } else {
    params.forEach((item: any) => {
      const valType = Object.prototype.toString.call(item);
      if (needIterate.includes(valType)) {
        initParams(item);
      }
    });
  }
};

// 处理 response
export const getDataFromResponse = (resp: any) => {
  const { data } = resp;
  if (!data || data.code > 0 || data.status > 0) {
    if (data && (data.status === 1006 || data.code === 2)) {
      // router.push('/login');
      if (window.location.pathname === '/signin.html') {
        Message.error(data.statusInfo);
      } else {
        window.location.replace('signin.html');
      }
      throw data || resp;
    }
    if (data.message) {
      if (!resp.config.url.includes('verification')) {
        Message.error(data.message);
      }
    }
    throw data || resp;
  }
  return data.data;
};

export const toFormData = (data: any) => {
  const form = new FormData();
  Object.keys(data).forEach(key => {
    const item = data[key];
    const addItem = (item: any) => {
      if (isPlainObject(item)) {
        item.uid || (item.id && form.append(key, item.uid || item.id));
      } else {
        form.append(key, item);
      }
    };
    if (isArray(item)) {
      item.forEach(addItem);
    } else {
      addItem(item);
    }
  });
  return form;
};

export const postFile = (url: any, data: any, options: any) => {
  const form = toFormData(data);
  return axios.post(url, form, options);
};

export async function get (url: any, data?: any, options = {}) {
  options = typeof options === 'object' ? options : {};
  initParams(data);
  const resp = await axios.get(url, { params: data, ...options });
  return getDataFromResponse(resp);
}

export async function post (url: any, data?: any, options = {}) {
  options = typeof options === 'object' ? options : {};
  initParams(data);
  const resp = await axios.post(url, data, options);
  const res = getDataFromResponse(resp);
  return res;
}

export async function put (url: any, data?: any, options = {}) {
  options = typeof options === 'object' ? options : {};
  initParams(data);
  const resp = await axios.put(url, data, options);
  const res = getDataFromResponse(resp);
  return res;
}

export default {
  get,
  post,
  put,
  postFile,
  getDataFromResponse,
};
