import Vue from 'vue';
import VueRouter from 'vue-router';
import { Service } from './modules/serviceList';
import { myOrders } from './modules/myOrders';
import { placeOrder } from './modules/placeOrder';
import { styleDemo } from './modules/styleDemo';
import { template } from './modules/template';
// import { myAccount } from './modules/myAccount';
import { IRouter } from './type';
import store from '@/store/index';
import { getCustomers, getOrgInfoByOId, own } from '@/api/login';

Vue.use(VueRouter);

// 使用 push 的方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err);
};

// 使用 replace 的方法
const RouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace (to: any) {
  return (RouterReplace.call(this, to) as any).catch((err: any) => err);
};

const lastRouter: IRouter[] = [
  {
    path: '/404',
    name: '404',
    hidden: false,
    meta: { title: '404' },
    component: () => import('@/views/error-page/404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   hidden: false,
  //   meta: { title: '系统登录' },
  //   component: () => import('@/views/login/index.vue')
  // }
];

export let asyncRouterMap: IRouter[] = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/layout/index.vue'),
    hidden: true,
    // beforeEnter: (to: any,_: any,next: any) => {
    // },
    redirect: '/service',
    children: Service
      .concat(myOrders)
      .concat(template)
    // .concat(myAccount)
  }
];

asyncRouterMap = asyncRouterMap.concat(placeOrder).concat(styleDemo).concat(lastRouter).concat(template);

const router = new VueRouter({
  mode: 'history',
  routes: asyncRouterMap
});

router.beforeEach(async (to, from, next) => {
  let res;
  if (store.state.user) {
    res = store.state.user;
  } else {
    res = await own();
  }
  let orgInfo;
  if (!store.state.OrgInfo) {
    const orgId = res?.orgs[0]?.orgId || 0;
    const orgInfo = await getOrgInfoByOId({ orgId: orgId });
    store.state.OrgInfo = orgInfo;
    store.commit('SET_ORGINFO', orgInfo);
  } else {
    orgInfo = store.state.OrgInfo;
  }
  if (to.path === 'template') {
    const enableStudentToTeacher = orgInfo?.entries?.split(',').map(Number).includes(10);
    if (!enableStudentToTeacher) {
      next(from.path);
      return;
    }
  }

  if (res?.orgs?.[0]?.isAdmin !== 1 &&
    res?.orgs?.[0]?.orgId !== 2 &&
    res?.orgs?.[0]?.isOwner !== 1) {
    location.replace('/signin.html');
    return;
  }
  next();
});

export default router;
