import { asyncRouterMap } from '../router/index';

// 引入模块
import * as eduInfo from './modules/education';
import * as formMap from './modules/formMap';
const state: any = {
  user: null,
  routers: asyncRouterMap,
  uploading: false,
  order_id: '',
  customerAppKey: null,
  formMap: formMap,
  organization: null,
  ...eduInfo,
  xdocPlan: null,
  OrgInfo: null,
  stage: null,
  subject: null
};

export default state;
