/* tslint:disable */
require('./404');
require('./back-top');
require('./bug');
require('./chart');
require('./clipboard');
require('./component');
require('./dashboard');
require('./didTask');
require('./documentation');
require('./drag');
require('./edit');
require('./education');
require('./email');
require('./example');
require('./excel');
require('./exit-fullscreen');
require('./eye-off');
require('./eye-on');
require('./form');
require('./fullscreen');
require('./guide-2');
require('./guide');
require('./hamburger');
require('./icon');
require('./international');
require('./language');
require('./like');
require('./link');
require('./list');
require('./lock');
require('./message');
require('./money');
require('./nested');
require('./password');
require('./pdf');
require('./people');
require('./peoples');
require('./qq');
require('./search');
require('./shopping');
require('./size');
require('./skill');
require('./star');
require('./tab');
require('./table');
require('./theme');
require('./tree-table');
require('./tree');
require('./user');
require('./wechat');
require('./willDoTask');
require('./zip');
