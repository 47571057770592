/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M0 54.9h36.6V128H0V54.9zm91.4-27.5H128V128H91.4V27.4zM45.7 0h36.6v128H45.7V0z"/>'
  }
})
