/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M54.1 127.9V99.2H7.5v-12h46.6V75H7.5V62h38L.1 0h22.6l32.6 45c3.6 5.2 6.4 9.7 8.4 13.5 1.8-3.1 5-7.8 9.3-14.2L104 0h24L82.3 62h38.3v13H74.3v12.4h46.4v12H74.3V128H54.1z"/>'
  }
})
