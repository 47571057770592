import { MutationTree } from 'vuex';
import { IFormMap } from './modules/formMap';

const mutations: MutationTree<any> = {
  SET_USER (state: any, user: any): void {
    state.user = user;
  },
  SET_ROUTERS (state: any, routers: any): void {
    state.routers = routers;
  },
  SET_UPLOADING (state: any, uploading: any): void {
    state.uploading = uploading;
  },
  SET_ORDERID (state: any, order_id: any): void {
    state.order_id = order_id;
  },
  SET_CUSTOMERAPPKEY (state: any, customerAppKey: any): void {
    state.customerAppKey = customerAppKey;
  },
  SET_EDUINFO (state: any, eduInfo: any): void {
    state.eduInfo = { ...eduInfo };
  },
  SET_FORMMAP (state: any, formMap: IFormMap): void {
    state.formMap = formMap;
  },
  SET_ORGANIZATION (state: any, organization: any): void {
    state.organization = organization;
  },
  SET_ORGINFO (state: any, OrgInfo: any): void {
    state.OrgInfo = OrgInfo;
  },
};

export default mutations;
