<template>
  <div class="tabs">
    <!-- <div class="slide" :style="activeStyle"></div> -->
    <div class="flex">
      <div v-for="(item, index) in tabs.filter(item => item.float !== 'right')" :key="index" class="flex">
        <div
          :class="{'active': index === value}"
          class="tabs-item"
          @click="clickHandler(item, index)"
        >
          <span>{{ item.name }}</span>
          <span v-if="item.desc !== undefined" class="desc m-l-3" v-html="item.desc"></span>
        </div>
        <div v-if="item.break" style="margin: 0px 10px"> | </div>
      </div>
    </div>
    <div style="margin-right: 20px" class="flex">
      <div v-for="item in tabs.filter(item => item.float === 'right')" :key="tabs.findIndex(tab => tab === item)">
        <div
          :class="{'active': tabs.findIndex(tab => tab === item) === value}"
          class="tabs-item"
          @click="clickHandler(item, tabs.findIndex(tab => tab === item))"
        >
          <span>{{ item.name }}</span>
          <span v-if="item.desc !== undefined" class="desc m-l-3" v-html="item.desc"></span>
        </div>
      </div>
      <slot name="right"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, null],
      default: 0
    }
  },
  computed: {
    // itemStyle () {
    //   const w = 1 / this.tabs.length
    //   return {
    //     width: `${w * 100}%`
    //   }
    // },
    // activeStyle () {
    //   let value = this.value
    //   if (this.tabs.length < 2) {
    //     value = 0
    //   }
    //   return {
    //     ...this.itemStyle,
    //     transform: `translateX(${ value * 100 }%)`
    //   }
    // },
  },
  data () {
    return {
    };
  },
  methods: {
    clickHandler (item, index) {
      this.$emit('input', index);
      this.$emit('click', item);
    }
  }
};
</script>

<style lang="scss" scoped>

$h: 40px;
$itemMinW: 80px;
.tabs {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1px;
  margin-left: 1px;
  font-size: 16px;
  color: mix(#fff,#48638f,20);
  text-align: center;
  line-height: $h;
  z-index: 1;
  background: mix(#fff,#48638f,96);
  &-item {
    position: relative;
    float: left;
    min-width: $itemMinW;
    position: relative;
    cursor: pointer;
    min-width: 150px;

    border-bottom: 3px solid mix(#fff,#48638f,90);
    transition: all .2s;

    &.active {
      color: mix(#fff,#48638f,0.1);
      border-bottom-color: #48638f;
    }
    &:last-child:after,
    &.active::after {
      width: 0;
    }
  }
}
</style>
