/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'willDoTask': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M914.022 95.296H738.127V35.534a35.534 35.534 0 00-71.068 0v59.762H357.022V35.534a35.534 35.534 0 00-71.068 0v59.762h-175.96A110.108 110.108 0 00.065 205.243v708.762a110.108 110.108 0 00109.98 109.979h803.978A110.108 110.108 0 001024 914.005V205.243A110.108 110.108 0 00914.022 95.296zm38.91 818.726a38.958 38.958 0 01-38.91 38.91H109.995a38.958 38.958 0 01-38.862-38.927V205.243a38.958 38.958 0 0138.91-38.91h803.979a38.958 38.958 0 0138.91 38.91z" _fill="#474747"/><path pid="1" d="M744.168 410.13L493.974 655.38 356.586 517.993a35.534 35.534 0 00-50.249 50.249L446.31 708.214a67.596 67.596 0 0094.796.468l252.81-247.787a35.534 35.534 0 10-49.748-50.75z" _fill="#474747"/>'
  }
})
