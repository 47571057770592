import { ActionTree } from 'vuex';
import { IFormMap } from './modules/formMap';

const actions: ActionTree<any, any> = {
  async setUser ({ commit }, user: any) {
    commit('SET_USER', user);
  },
  async setOrganization ({ commit }, user: any) {
    commit('SET_ORGANIZATION', user);
  },
  async setUploading ({ commit }, uploading: any) {
    commit('SET_UPLOADING', uploading);
  },
  async setEduInfo ({ commit }, eduInfo: any) {
    commit('SET_EDUINFO', eduInfo);
  },
  async setOrderId ({ commit }, order_id: any) {
    commit('SET_ORDERID', order_id);
  },
  async setCustomerAppKey ({ commit }, customerAppKey: any) {
    commit('SET_CUSTOMERAPPKEY', customerAppKey);
  },
  async setFormMap ({ commit }, formMap: IFormMap) {
    commit('SET_FORMMAP', formMap);
  }
};

export default actions;
