
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { own, getCustomers, getAppKeysByOId } from '@/api/login';
import '@/business/FormDataInCache';

@Component({
  name: 'app',
})
export default class extends Vue {
  userFlag = false
  // 存储用户信息
  @Action('setUser') setUser: any;
  @Action('setCustomerAppKey') setCustomerAppKey: any;
  @Action('setFormMap') setFormMap: any;
  @Action('setOrganization') setOrganization: any;
  async mounted () {
    this.userFlag = false;
    try {
      const res = await own();
      if (res) {
        const r = await getCustomers();
        if (r && r.length) {
          const appKeys = await getAppKeysByOId({
            orgIds: [r[0].id]
          });
          const customerAppKey = appKeys.find((item: any) => item.isActive && !item.isTest);
          if (!customerAppKey) {
            this.userFlag = true;
            this.$message.error('您没有访问的权限');
            window.location.replace('signin.html');
            return;
          }
          await this.setCustomerAppKey(customerAppKey);
        } else {
          this.userFlag = true;
          this.$message.error('您没有关联的企业');
          window.location.replace('signin.html');
          return;
        }
        await this.setUser(res);
        await this.setOrganization(r[0]);
        const formString = window.localStorage.getItem('hexin_business_form');
        await this.setFormMap(JSON.parse(formString as string));
      }
      this.userFlag = true;
    } catch (e) {
      this.userFlag = true;
      window.location.replace('signin.html');
    }
  }
}
