import api from '../api';
import store from '../../store';
import { ILoginParams } from './types';

const getUCUrl = (url: string) => {
  const isInstra = location.origin.indexOf('hexin.im') > 0 || location.origin.indexOf('localhost') > 0 || location.origin.indexOf('192.') > 0;
  const suffix = isInstra ? 'http://uc.hexin.im/api' : 'http://uc.hexinedu.com/api';
  return suffix + url;
};

export const logout = () => {
  api.post(getUCUrl('/quitLogin'));
  store.dispatch('user/userAction', null);
};

export const login = (params: ILoginParams) => api.post(getUCUrl('/login'), {
  ...params,
  'appkey': process.env.VUE_APP_THEMIS_APP_KEY,
  'appSecret': process.env.VUE_APP_THEMIS_APP_SECRET,
});

export const own = async () => {
  const data = await api.get(getUCUrl('/getOwn'), { params: { timestamp: new Date().getTime() } });
  return data;
};

/**
 * 获取企业列表
 * @returns
 */
export const getCustomers = () => {
  return api.get(getUCUrl('/businessOrg/getUserAndOrgList'));
};

/**
 * 通过企业ID获取企业应用
 * @param data
 * @returns
 */
export const getAppKeysByOId = (data: { orgIds: number[] }) => {
  return api.post(getUCUrl('/orgAppl/getAllByOrg'), data);
};

/**
 * 更新当前用户密码
 * @param data
 * @returns
 */
export const updatePassword = (data: { newPassword: string; oldPassword: string }) => {
  return api.post(getUCUrl('/updatePassword'), data);
};

/**
 * 通过企业ID获取企业信息
 * @param data
 * @returns
 */
export const getOrgInfoByOId = (params: { orgId: number }) => {
  return api.get(getUCUrl('/businessOrg/getOne'), params);
};

export const getOrgList = () => {
  return api.get(getUCUrl('/businessOrg/getAll'));
};
