import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import '@babel/polyfill'

// 样式重置
import 'normalize.css';
// 导入样式库
import ElementUI from 'element-ui';
// 重置element 样式
// import '@/styles/element-variables.scss';
// 使用svg图标
import SvgIcon from 'vue-svgicon';
import '@/icons/components/index';
// 导入样式
import '@/styles/theme/index.css';
import '@/styles/index.scss';

// 引入阿里图标库里面的图标
import '@/icons/iconfont/iconfont.css';
// 引入阿里图标库里面含有颜色的图标
import '@/icons/iconfont/iconfont.js';

import Tabs from './components/Tabs.vue';
import HeaderBar from './components/HeaderBar.vue';

// @ts-ignore
import RecycleScroller from 'vue-virtual-scroller';

Vue.use(ElementUI);
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
});
Vue.use(RecycleScroller);

Vue.prototype.$updateQuery = function (...queries: any) {
  this.$dontReload = true;
  this.$router.replace({ query: Object.assign({}, this.$route.query, ...queries) });
  setTimeout(() => { this.$dontReload = false; }, 0);
};

Vue.config.productionTip = false;

Vue.prototype.$EventBus = new Vue();

// prod环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}

Vue.component(Tabs.name, Tabs as any);
Vue.component('header-bar', HeaderBar as any);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
