/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'didTask': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M738.826 1005.166c-150.227 0-272.002-121.916-272.002-272.303 0-150.408 121.775-272.324 272.002-272.324 150.207 0 271.982 121.916 271.982 272.324 0 150.387-121.775 272.303-271.982 272.303zm-.04-508.225c-128.582 0-232.79 104.348-232.79 233.09s104.208 233.111 232.79 233.111c128.562 0 232.77-104.367 232.77-233.11s-104.208-233.09-232.77-233.09zm10.561 318.243s-3.694 3.675-7.409 3.675a18.01 18.01 0 01-25.941 0l-74.11-80.916a17.669 17.669 0 010-25.741c7.41-7.369 22.247-7.369 25.942 0l63.006 69.873 129.686-117.7a18.01 18.01 0 0125.942 0 17.71 17.71 0 010 25.76l-137.116 125.05zM391.53 682.667H190.745a20.078 20.078 0 010-40.157H391.53a20.078 20.078 0 110 40.157zM170.667 261.02a20.078 20.078 0 0120.078-20.079h481.882a20.078 20.078 0 010 40.157H190.745a20.078 20.078 0 01-20.078-20.078zM512 461.804H190.745a20.078 20.078 0 010-40.157H512a20.078 20.078 0 010 40.157zM813.176 120.47a80.314 80.314 0 00-80.313-80.314H130.51a80.314 80.314 0 00-80.314 80.314v762.98a80.314 80.314 0 0080.314 80.314h366.833a346.112 346.112 0 0040.418 40.779H130.51a120.47 120.47 0 01-120.47-120.47V120.47A120.47 120.47 0 01130.51 0h602.353a120.47 120.47 0 01120.47 120.47v293.668a340.189 340.189 0 00-40.157-8.534V120.471z"/>'
  }
})
