import { IRouter } from '../type';
export const placeOrder: IRouter[] = [
  {
    path: '/create',
    name: 'create',
    component: () => import('@/views/placeOrder/index.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/placeOrder/components/Success.vue')
  }
];
