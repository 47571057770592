import { IRouter } from '../type';
export const styleDemo: IRouter[] = [
  {
    path: '/style-demo',
    name: 'styleDemo',
    component: () => import('@/views/StyleDemo/index.vue'),
    redirect: '/style-demo/book-core',
    children: [
      {
        path: 'book-ext-cfg',
        component: () => import('@/views/StyleDemo/book-ext-cfg/index.vue'),
      },
      {
        path: 'book-load-style',
        component: () => import('@/views/StyleDemo/book-load-style/index.vue'),
      },
      {
        path: 'book-version',
        component: () => import('@/views/StyleDemo/book-version/index.vue'),
      },
      {
        path: 'book-core',
        component: () => import('@/views/StyleDemo/book-core/index.vue'),
      },
      {
        path: 'book-header',
        component: () => import('@/views/StyleDemo/book-header/index.vue'),
      },
      {
        path: 'book-answerbook',
        component: () => import('@/views/StyleDemo/book-answerbook/index.vue'),
      },
      {
        path: 'book-footer',
        component: () => import('@/views/StyleDemo/book-footer/index.vue'),
      },
      {
        path: 'book-sidebar',
        component: () => import('@/views/StyleDemo/book-sidebar/index.vue'),
      },
      {
        path: 'book-watermark',
        component: () => import('@/views/StyleDemo/book-watermark/index.vue'),
      },
      {
        path: 'book-font-style',
        component: () => import('@/views/StyleDemo/book-font-style/index.vue'),
      },
      {
        path: 'question-config',
        component: () => import('@/views/StyleDemo/question-config/index.vue'),
      },
      {
        path: 'paragraph-config',
        component: () => import('@/views/StyleDemo/paragraph-config/index.vue'),
      },
      {
        path: 'chapter-config',
        component: () => import('@/views/StyleDemo/chapter-config/index.vue'),
      },
      {
        path: 'chapter-class-config',
        component: () => import('@/views/StyleDemo/chapter-class-config/index.vue'),
      },
      {
        path: 'border-box',
        component: () => import('@/views/StyleDemo/border-box/index.vue'),
      },
      {
        path: 'json',
        component: () => import('@/views/StyleDemo/json-config/index.vue'),
      },
      {
        path: 'style-bank',
        component: () => import('@/views/StyleDemo/style-bank/index.vue')
      },
      {
        path: 'style-guide',
        component: () => import('@/views/StyleDemo/guide-config/index.vue'),
      },
    ]
  },
  {
    path: '/style-demo-v2',
    name: 'styleDemoV2',
    component: () => import('@/views/StyleDemoV2/index.vue'),
  }
];
